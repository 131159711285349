import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import {filter, map} from 'rxjs/operators';
import {useTranslation} from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {UserPermissions} from '../../components/shared/AccessControl';
import {Dashboard} from '../../model/Dashboard';
import {Auth0User, isPowerOrg} from '../../model/Auth0User';
import UserRoleService from '../../services/UserRoleService';
import TermsAndConditionsDialog from '../../components/dialogs/TermsAndConditionsDialog';
import UserService from '../../services/UserService';
import {UserTeamEntity} from '../../model/User';
import DynamicIcon from '../../components/DynamicIcon';
import CampaignsCards from './components/CampaignsCards';
import {TestAttributes} from '../../TestAttributes';
import {FeatureName} from '../../../paths';
import {SpartanMenuItem} from '../../model/SpartanMenuItems';
import {NextgenFormSchema} from '../../form/api/middleware/NextgenFormSchema';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {AxiosResponse} from 'axios';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';
import MaterialUiDynamicIcon from '../../components/MaterialUiDynamicIcon';

function DashboardPage() {
  const {useAxiosFormAPI} = useAxiosContext();
  const theme = useTheme();
  const {t} = useTranslation();
  const {setLoading} = useLoading();
  const auth0 = useAuth0<Auth0User>();
  const {user} = auth0;
  const power = isPowerOrg(user);
  const [dashboardItems, setDashboardItems] = useState<Array<SpartanMenuItem>>([]);
  const [userTeams, setUserTeams] = useState<UserTeamEntity[]>([]);
  const [userPermissions, setUserPermissions] = useState<Array<UserPermissions>>([]);
  const [anchorElMenus, setAnchorElMenus] = useState<any>([]);
  const sessionStorageKey = 'Terms&ConditionsPowerAccepted';
  const conditionsPowerAccepted = sessionStorage.getItem(sessionStorageKey);

  const buttonHoverSX = {
    color: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  };
  const [
    {response: getMenuItemsResponse, error: getMenuItemsError, loading: getMenuItemsResponseLoading},
    getMenuItems,
  ] = useAxiosFormAPI<NextgenFormSchema>(
    {
      url: `/featurefields`,
      method: 'GET',
      params: {feature_name: FeatureName.DASHBOARD},
    },
    {manual: true}
  );

  const [{error: getDashboardError}, getDashboard] = useAxiosFormAPI<Dashboard>(
    {
      url: '/feature/dashboard',
      method: 'GET',
    },
    {manual: true}
  );

  useEffect(() => {
    setLoading(getMenuItemsResponseLoading, 'DashboardPage');
  }, [getMenuItemsResponseLoading]);

  useEffect(() => {
    if (getMenuItemsResponse) {
      setDashboardItems(getMenuItemsResponse?.data?.menu_items || []);
    }
  }, [getMenuItemsResponse]);

  useEffect(() => {
    if (getMenuItemsError) {
      NotificationService.getInstance().sendNotification(
        getMenuItemsError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [getMenuItemsError]);

  useEffect(() => {
    if (getDashboardError) {
      NotificationService.getInstance().sendNotification(
        getDashboardError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [getDashboardError]);

  useEffect(() => {
    const subscription = UserRoleService.getInstance()
      .getSelectedRole()
      .pipe(filter((selectedRole) => !!selectedRole))
      .subscribe(() => {
        getDashboard().then((dashboardResponse: AxiosResponse<Dashboard>) => {
          setUserPermissions(dashboardResponse?.data?.actions ?? []);
        });
      });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const subscription = UserService.getInstance()
      .get()
      .pipe(map((user) => user?.teams || []))
      .subscribe((teams) => setUserTeams(teams));
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    getMenuItems();
  }, [UserRoleService.getInstance().getCurrentValue()]);

  function handleMenuItemOpen(event: React.MouseEvent<HTMLElement>, menuItemId: string): void {
    setAnchorElMenus({...anchorElMenus, [menuItemId]: event.target});
  }

  function handleMenuItemClose(menuItemId: string) {
    setAnchorElMenus({...anchorElMenus, [menuItemId]: null});
  }

  function getCardButtons(dashboardItem: SpartanMenuItem): JSX.Element[] | undefined {
    const dashboardItemChild = dashboardItem?.children?.filter((child) => !child.children);
    if (dashboardItemChild && dashboardItemChild.length > 0) {
      return dashboardItemChild?.map((child) => (
        <Link className="main-cta" to={child.action || ''} key={child.menu_item_id}>
          <Button
            sx={{
              display: 'flex',
              backgroundColor: 'inherit',
              ...buttonHoverSX,
            }}
            {...{[TestAttributes.BUTTON_NAME]: `${dashboardItem.label}-${child.label}`}}
          >
            {t(child.label)}
          </Button>
        </Link>
      ));
    }
  }

  function getCardMenu(dashboardItem: SpartanMenuItem): JSX.Element[] | undefined {
    const menuItems = dashboardItem?.children?.filter((child) => child.children);
    if (menuItems) {
      return menuItems.map((dashboardItemChild) => (
        <Grid item key={dashboardItemChild.menu_item_id}>
          <IconButton
            onClick={(event) => handleMenuItemOpen(event, dashboardItemChild.menu_item_id)}
            sx={buttonHoverSX}
            size={'small'}
            {...{[TestAttributes.BUTTON_NAME]: `menu-${dashboardItem?.label}`}}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorElMenus[dashboardItemChild.menu_item_id]}
            keepMounted
            open={Boolean(anchorElMenus[dashboardItemChild.menu_item_id])}
            onClose={() => handleMenuItemClose(dashboardItemChild.menu_item_id)}
          >
            {dashboardItemChild?.children?.map((menuItem) => (
              <Link
                to={menuItem.action || ''}
                key={menuItem.menu_item_id}
                {...{[TestAttributes.BUTTON_NAME]: `${dashboardItem?.label}-${menuItem.label}`}}
              >
                <MenuItem
                  onClick={() => {
                    handleMenuItemClose(dashboardItemChild.menu_item_id);
                  }}
                  sx={buttonHoverSX}
                >
                  {t(menuItem.label)}
                </MenuItem>
              </Link>
            ))}
          </Menu>
        </Grid>
      ));
    }
  }
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={8} md={6} sm={12}>
            <Grid container spacing={3}>
              {dashboardItems.map((dashboardItem: SpartanMenuItem) => (
                <Grid key={'feature-' + dashboardItem.label} item xs={12} sm={12} md={6} lg={4}>
                  <Card id={dashboardItem.label.replace(' ', '-').toLowerCase()}>
                    <CardContent sx={{display: 'flex', justifyContent: 'center', flexFlow: 'column'}}>
                      <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        {dashboardItem.icon ? (
                          <MaterialUiDynamicIcon
                            iconName={dashboardItem.icon}
                            color={theme.palette.primary.main}
                            fontSize="large"
                          />
                        ) : (
                          <DynamicIcon
                            iconName={dashboardItem?.label?.toLowerCase().split('.')?.pop() || ''}
                            color={theme.palette.primary.main}
                            fontSize="large"
                          />
                        )}
                      </Box>
                      <Typography variant={'h2'} sx={{textTransform: 'capitalize', textAlign: 'center'}}>
                        {t(dashboardItem.label)}
                      </Typography>
                    </CardContent>
                    {dashboardItem?.children && (
                      <CardActions sx={{display: 'flex', pb: '1rem'}}>
                        <Grid container spacing={2}>
                          <Grid item xs={8} sx={{display: 'flex'}} justifyContent={'left'} alignItems={'center'}>
                            {getCardButtons(dashboardItem)}
                          </Grid>
                          <Grid item xs={4} sx={{display: 'flex'}} justifyContent={'right'}>
                            {getCardMenu(dashboardItem)}
                          </Grid>
                        </Grid>
                      </CardActions>
                    )}
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CampaignsCards userPermissions={userPermissions} />
            {!power && userTeams && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="teams-panel"
                  {...{[TestAttributes.ACCORDION_NAME]: 'teams-panel'}}
                  id="teams-panel"
                >
                  <Typography variant={'body1'} sx={{textTransform: 'capitalize'}}>
                    {t('dashboard.teams')} ({userTeams.length})
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {userTeams.map((team: any, index: number) => {
                    return (
                      <Box key={team.team_id} sx={{margin: theme.spacing(1)}}>
                        <Typography variant={'body1'}>{team.name}</Typography>
                        <Typography variant={'body1'}>{team.description}</Typography>
                        {index !== userTeams.length - 1 && <Divider />}
                      </Box>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            )}
          </Grid>
          {!conditionsPowerAccepted && <TermsAndConditionsDialog />}
        </Grid>
      </Container>
    </Box>
  );
}

export default DashboardPage;
